import React, { useState, useEffect } from "react"
import MobileHeader from "../components/MobileHeader"
import TopNav from "../components/header"
import MockupImage from "../images/Mockup.png"
import BlackFridayImage from "../images/blackfriday.svg"
import Footer from "../components/footer"
import Typist from "react-typist"
import Button from "../components/ctaButton"

const BlackFriday = () => {
    const [count, setCount] = useState(1);

    useEffect(() => {
      setCount(1);
    }, [count]);
    return(
  <div className="h-screen ">
    <div className="h-20 relative bg-black">
      <TopNav className=" relative text-gray-500 m-20"></TopNav>
      <MobileHeader></MobileHeader>
    </div>
    <div className=" text-white bg-black flex flex-col lg:flex-row sm:flex-col  pt-16 lg:px-12 sm:px-4 lg:justify-around">
      <div className="flex flex-col">
        <img className="lg:w-96" src={BlackFridayImage}></img>
        <h3 className="text-center pt-12 text-lg ">
         {count ? (<Typist cursor={{ hideWhenDone: true }} onTypingDone={() => setCount(0)} >
            <span>{TypingOptions[0]}</span>
            <Typist.Delay ms={1000} />
            <Typist.Backspace count={TypingOptions[0].length} delay={200} />
            <span>{TypingOptions[1]}</span>
            <Typist.Delay ms={1000} />
            <Typist.Backspace count={TypingOptions[1].length} delay={200} />
            <span>{TypingOptions[2]}</span>
            <Typist.Delay ms={1000} />
            <Typist.Backspace count={TypingOptions[2].length} delay={200} />
          </Typist>): ""}
        </h3>
        <div className="pt-12 align-center self-center">
        <Button  buttonUrl="contact" buttonTitle="Take the deal"></Button>
        </div>
      </div>
      <div>
        <img src={MockupImage}></img>
      </div>
    </div>
    <Footer></Footer>
  </div>
)}

const TypingOptions = [
  "Mobile App Development",
  "Corporate Website Development",
  "Web App Development",
]

export default BlackFriday
